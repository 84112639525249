<template>
  <div class="animate__animated animate__fadeIn">
    <div
      class="row vh-100 bg-dark g-0 d-flex align-content-around align-content-lg-center camera-container"
    >
      <div class="camera-canvas">
        <ImageCamera
          ref="imageCamera"
          @after-capture-frame="afterCaptureImageCamera"
        />
      </div>
      <div
        class="position-absolute w-100 d-flex justify-content-end fixed-bottom"
      >
        <div class="operation-buttons m-3">
          <button
            type="button"
            class="btn btn-link link-light d-block p-0"
            @click.prevent="stopImageCamera()"
          >
            <i class="bi bi-x-circle-fill fs-1"></i>
          </button>
          <button
            type="button"
            class="btn btn-link link-light d-block p-0"
            @click.prevent="captureImageCamera()"
          >
            <i class="bi bi-record-circle-fill fs-1"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageCamera from '@/components/AppImageCamera.vue'

export default {
  name: 'InspectionImageCamera',
  components: {
    ImageCamera,
  },

  emits: ['hiddenCamera', 'captureCamera'],

  mounted() {
    this.$refs.imageCamera.startStream()
  },
  unmounted() {},

  methods: {
    stopImageCamera() {
      this.$emit('hiddenCamera')
    },
    captureImageCamera() {
      this.$refs.imageCamera.captureFrame()
    },
    afterCaptureImageCamera(dataURL, fileType) {
      this.$emit('captureCamera', dataURL, fileType)
    },
  },
}
</script>

<style scoped>
.camera-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
</style>
